import * as React from "react"
import Layout from "../components/Layout";
import localize from "../components/localize";
import {graphql} from "gatsby";
import SEO from "../components/SEO";
import Div100vh from "react-div-100vh";
import Job from "../components/Job";
import MyPortableText from "../components/MyPortableText";

export const query = graphql`
    {
    site {
    siteMetadata {
    lang{
        _type
        en
        de
        }
        angebot{
        _type
        en
        de
        }
        kunden{
        _type
        en
        de
        }
        impressum{
        _type
        en
        de
        }
        datenschutz{
        _type
        en
        de
        }
        agbs{
        _type
        en
        de
        }
        telefon{
        _type
        en
        de
        }
        startseite{
        _type
        en
        de
        }
        offeneJobs{
        _type
        en
        de
        }
        keineJobs{
        _type
        en
        de
        }
        mehrInfo{
        _type
        en
        de
        }
    }
    }
    allSanityImpressum {
    edges {
      node {
           title {
          en
          de
          _type
        }
        _rawText
    }
  }
  }
  allSanitySettings {
    edges {
      node {
      standorte {
          street
          plz
          _rawStadt
          _rawName
        }
        telefon
        skype
        email
        linkedin
        jobsexternal
        jobsexternallink
     }
     }
    }
    allSanityNetzwerk {
    edges {
      node {
      title{
      en
      de
      _type
      }
      }
      }
      }
    }
   `;

const ImpressumPage = ({data, location}) => {

    const page = data.allSanityImpressum.edges[0].node;

    const settings = data.allSanitySettings.edges[0].node;
    const netzwerk = data.allSanityNetzwerk.edges[0].node;


    return (
        <div id={"top"}>
            <Layout location={location} metadata={data.site.siteMetadata} settings={settings} netzwerk={netzwerk.title}>
                <SEO title={page.title + " | Dr. Barbara Stampf"}/>
                <div className={"angebot__outer"}>
                    <div className="color-bg bg-green left3"></div>
                    <div className="color-bg bg-blue right3"></div>
                    <div className="angebot__inner">
                        <h1 className={"uppercase color-light"}>{page.title}</h1>
                        <div className="description imprint-description">
                            <MyPortableText value={page._rawText}></MyPortableText>
                        </div>
                    </div>
                </div>
            </Layout>
        </div>
    )
}

export default localize(ImpressumPage)
